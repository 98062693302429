.#{shared-var("PageTemplateBS") }-#{shared-var("BuyingGuidePageBS") } {
  $card-deck-overlay-bg: $card-bg !default;

  @include override-card-deck-overlay(
    $card-deck-overlay-bg,
    true,
    true,
    false,
    $card-deck-opaque-overlay
  );

  table.table {
    @extend .mx-4;
    width: calc(100% - #{2 * map-get($spacers, 4)});
  }

  .#{shared-var("ImageFootnoteCardDeckBS")}-items {
    .card-footer {
      background-color: $card-deck-overlay-bg !important;
    }
  }
}
