.#{shared-var("PageTemplateBS") }-#{shared-var("AssemblyGuidePageBS") } {
  h1,
  h2,
  h3,
  h4 {
    @extend .font-weight-bold;
  }

  h2 {
    @extend .h4;
  }

  h3 {
    font-size: inherit;
  }

  h4 {
    @extend .h5;
  }

  $card-deck-overlay-bg: $card-bg !default;

  @include override-card-deck-overlay(
    $card-deck-overlay-bg,
    true,
    true,
    false,
    $card-deck-opaque-overlay
  );
}
